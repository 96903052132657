<template>
  <v-app>
    <core-snackbar />

    <v-container
      fluid
      class="grey lighten-3 fill-height background"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/imagens/background-login.jpg') + ')',
      }"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-card>
            <v-toolbar color="primary" flat dark>
              <v-toolbar-title> Login </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pa-4">
              <v-form @submit="login">
                <v-row>
                  <v-col>
                    <validation-observer ref="observer">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Usuário"
                        vid="nome_usuario"
                      >
                        <v-text-field
                          v-model="form.nome_usuario"
                          outlined
                          hide-details="auto"
                          label="Usuário"
                          class="mb-4"
                          autocomplete="nope"
                          :error-messages="errors"
                          prepend-icon="mdi-account-circle"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Senha"
                        vid="senha"
                      >
                        <v-text-field
                          v-model="form.senha"
                          outlined
                          hide-details="auto"
                          type="Password"
                          label="Senha"
                          :error-messages="errors"
                          prepend-icon="mdi-lock"
                        />
                      </validation-provider>
                    </validation-observer>
                  </v-col>
                </v-row>
                <v-row class="mt-4">
                  <v-col class="text-right">
                    <v-btn
                      color="black"
                      dark
                      outlined
                      :loading="carregando"
                      type="submit"
                    >
                      <v-icon class="mr-2"> mdi-login </v-icon>
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import autenticacao from "@/api/autenticacao";

export default {
  components: {
    CoreSnackbar: () => import("@/components/core/CoreSnackbar"),
  },

  data: () => ({
    carregando: false,
    form: {
      usuario: null,
      senha: null,
    },
  }),

  methods: {
    ...mapMutations({
      setToken: "usuario/SET_TOKEN",
      setUsuario: "usuario/SET_USUARIO",
    }),

    async login(evento) {
      evento.preventDefault();

      try {
        this.carregando = true;

        const resposta = await autenticacao.login(this.form);

        this.setToken(resposta.data.token);
        this.setUsuario(resposta.data.usuario);

        this.$router.push("/");
      } catch (e) {
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(this.$erroApi.validacao(e));
        } 
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>

<style lang="sass">
.background
  background-size: cover
</style>
